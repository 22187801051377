<template>
  <div class="home">
    <div class="bg">
      <div class="quota">名额剩余{{ quantity }}个</div>
      <table class="qgg-table">
        <thead>
        <tr>
          <th>合作时间</th>
          <th>手机号</th>
          <th>合作类型</th>
          <th>金额</th>
        </tr>
        </thead>
        <tbody>
        <!--        <vue-seamless-scroll :data="listData" class="warp">-->
        <tr class="item" v-show="index<10" v-for="(item, index) in listData" :key="index">
          <td>{{ item.date }}</td>
          <td>{{ item.tel }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.amount }}</td>
        </tr>
        <!--        </vue-seamless-scroll>-->

        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

const moment = require('moment')
const randomDate = (startDate, endDate) => {
  let date = new Date(+startDate + Math.random() * (endDate - startDate))
  let hour = 0 + Math.random() * (23 - 0) | 0
  let minute = 0 + Math.random() * (59 - 0) | 0
  let second = 0 + Math.random() * (59 - 0) | 0
  date.setHours(hour)
  date.setMinutes(minute)
  date.setSeconds(second)
  return date
}

// 生产当月的开始日期
const startDate = moment()
  .startOf('month')
  .toDate()
// 截止日期
const endDate = new Date()

function randomTime () {
  const timestamp = moment(randomDate(startDate, endDate))
  const time = timestamp.format('YYYY-MM-DD HH:mm:ss') // '2019-08-14 21:19:36'
  return {
    time,
    timestamp
  }
}

function getQueryString (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

function getMoble () {

  var prefixArray = new Array('134', '135', '136', '137', '138', '139', '147', '148', '150', '151', '152', '157', '158', '159', '165', '172', '178', '182', '183', '184', '187', '188', '195', '197', '198', '133', '149', '153', '162', '173', '174', '177', '180', '181', '189', '190', '191', '193', '199', '130', '131', '132', '145', '146', '155', '156', '166', '167', '171', '175', '176', '185', '186', '196', '170')
  var i = parseInt(10 * Math.random())
  var prefix = prefixArray[i]
  for (var j = 0; j < 8; j++) {
    prefix = prefix + Math.floor(Math.random() * 10)
  }
  var reg = /^(\d{3})\d{4}(\d{4})$/
  return prefix.replace(reg, '$1****$2')
}

function getAmount () {
  let amount = null
  const arr = ['388', '388', '588', '588', '588']
  const index = Math.floor((Math.random() * arr.length))
  amount = getQueryString('a')
  if (!amount) {
    amount = arr[index]
  }
  return amount
}

export default {
  name: 'Home',
  components: {
    vueSeamlessScroll
  },
  data () {
    return {
      listData: [],
      quantity: 20,
      amount: null
    }
  },
  created: function () {
    const now = moment().format('YYYY-MM-DD')
    for (let i = 0; i < 30; i++) {
      this.listData.push({
        tel: getMoble(),
        date: now,
        type: '已合作',
        amount: getAmount()
      })
    }
    // this.listData.sort(this.compare('date', 'timestamp'))
  },
  mounted () {
    let s = this.getQueryString('s')
    if (s) {
      this.quantity = s
    }
    let amount = this.getQueryString('a')
    if (amount) {
      this.amount = amount
    }
    this.search()
  },
  methods: {
    search () {
      const delayTime = 2 * 1000
      let res = this.getData()
      if (res === 0) {
        // 清除定时器
        clearInterval(this.timer)
        // console.log('查询成功')
      } else {
        // console.log('查询超时')
        // 清除定时器
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          this.search()
        }, delayTime)
      }
    },
    getData () {
      const now = moment().format('YYYY-MM-DD')
      this.listData.push({
        tel: getMoble(),
        date: now,
        type: '已合作',
        amount: getAmount()
      })
      this.listData.shift()
    },
    getQueryString (name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }

      return null
    },
    compare (p, q) { //这是比较函数
      return function (m, n) {
        var a = m[p][q]
        var b = n[p][q]
        return a - b //升序
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .bg {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    /** Table 表格样式 **/
    .qgg-table {
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #c6c6c6 !important;
      margin-bottom: 20px;
    }

    .qgg-table th {
      border-collapse: collapse;
      border-right: 1px solid #c6c6c6 !important;
      border-bottom: 1px solid #c6c6c6 !important;
      background-color: #ddeeff !important;
      padding: 5px 9px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }

    .qgg-table td {
      border-collapse: collapse;
      border-right: 1px solid #c6c6c6 !important;
      border-bottom: 1px solid #c6c6c6 !important;
      padding: 5px 9px;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      word-break: break-all;
      transition: all 0ms ease-in 0s;
      overflow: hidden;
    }

    .qgg-table tr:nth-child(odd) {
      background-color: #fff !important;
    }

    .qgg-table tr:nth-child(even) {
      background-color: #f8f8f8 !important;
    }

    .quota {
      text-align: left;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 6px;
      color: #e1251b;
    }
  }

  .warp {
    height: 270px;
    width: 500px;
    margin: 0 auto;
    overflow: hidden;

    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;

      tr,
      a {
        display: block;
        height: 30px;
        line-height: 30px;
        display: flex;
        justify-content: space-between;
        font-size: 15px;
      }
    }
  }
}
</style>
