import request from '@/utils/request'

export default {
  lt(data) {
    return request.get('/act/lottery/lt', data)
  }

  // var api = {
  //   detail: '/act/lottery/wx/detail',
  //   lt: '/act/lottery/lt',
  //   my: '/act/lottery/wx/luck/record/my',
  //   config: '/act/lottery/wx/prize/config',
  //   record: '/act/lottery/wx/luck/record/my',
  //   friends: '/act/act/addtimes/task/wx/friends',
  //   moments: '/act/act/addtimes/task/wx/moments',
  //   receiver: '/act/lottery/wx/luck/receiver/update',
  // }
}
